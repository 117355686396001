/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/docsify@4.13.1/lib/docsify.min.js
 * - /npm/docsify@4.13.1/lib/plugins/emoji.min.js
 * - /npm/docsify-themeable@0.9.0/dist/js/docsify-themeable.min.js
 * - /npm/@markbattistella/docsify-sidebarfooter@5.0.7/dist/docsify-sidebar.min.js
 * - /npm/docsify-tabs@1.6.3/dist/docsify-tabs.min.js
 * - /npm/docsify-example-panels@1.1.0/dist/docsify-example-panels.min.js
 * - /npm/docsify-copy-code@2.1.1/dist/docsify-copy-code.min.js
 * - /npm/docsify-plugin-flexible-alerts@1.1.1/dist/docsify-plugin-flexible-alerts.min.js
 * - /npm/prismjs@1.21.0/components/prism-yaml.min.js
 * - /npm/prismjs@1.21.0/components/prism-json.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
